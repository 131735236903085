export class UserDTO {
    constructor(
        readonly username: string,
        readonly rights: string,
        readonly flood: boolean,
        readonly admin: boolean,
        readonly cities: string[],
        readonly citiesAlarm: string[],
        readonly email: string = '',
        readonly emailAlarm: boolean,
        readonly phone: string,
        readonly smsAlarm: boolean,
        readonly language: string,
        readonly userProfile: string
    ) {}
}
