








import { Vue, Component, Prop } from 'vue-property-decorator'

import { City } from '@/model/City'
import { User } from '@/model/User'

import { showToast } from '@/utils/toast'

import { ICityService } from '@/services/city/ICityService'
import { IUserService } from '@/services/user/IUserService'

import UserUpdateFormComponent from './UserUpdateForm.vue'

@Component({
    components: {
        UserUpdateFormComponent
    }
})
export default class UserUpdate extends Vue {
    @Prop({ type: String, required: true }) readonly username!: string

    user: User | null = null
    cities: City[] = []

    private cityService!: ICityService
    private userService!: IUserService

    created(): void {
        this.userService = this.$services.get<IUserService>('user')
        this.cityService = this.$services.get<ICityService>('city')
    }

    async mounted(): Promise<void> {
        this.user = await this.userService.getUser(this.username)
        const cityCollection = this.cityService.getCityCollection()
        this.cities = cityCollection.getCities()
        console.log("test")
        console.log(this.user.userProfile)
    }

    onUpdateUserRequested(newUser: User): void {
        this.userService
            .updateUser(this.username, newUser)
            .then(() => {
                this.$router.push({ name: 'Users' })
                showToast('is-success', 3000, `${this.$t('dialogs.update_user.success')}`)
            })
            .catch(() => showToast('is-danger', 3000, `${this.$t('dialogs.update_user.error')}`))
    }
}
