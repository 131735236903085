







































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'

import { City } from '@/model/City'
import { Permissions, PermissionsValues } from '@/model/Permissions'
import { SorterHelper } from '@/model/SorterHelper'
import { User } from '@/model/User'

import RequiredField from './RequiredField.vue'
import LanguageField from './LanguageField.vue'

@Component({
    components: {
        RequiredField,
        LanguageField
    }
})
export default class UserUpdateFormComponent extends Vue {
    @Prop({ type: User, required: true }) readonly user!: User
    @Prop({ type: Array }) readonly cities!: City[]

    userInput = ''

    private sorterHelper!: SorterHelper

    private allPermissions: Permissions[] = PermissionsValues
    private selectAllCities = false
    private newUserCities: any[] = []

    email = ''
    isAdmin = false
    floodAccess = false
    selectedLanguage = ''
    userLanguage = ''
    permissions: Permissions = Permissions.LIVE_ONLY
    userCities: string[] = []

    userProfile = ''
    phone = ''
    emailAlarm = false
    smsAlarm = false


    created(): void {
        
        this.email = this.user.email
        this.userLanguage = this.user.language
        this.isAdmin = this.user.isAdmin
        this.floodAccess = this.user.floodAccess
        this.permissions = this.user.permissions
        this.userCities = this.user.cities.map((city) => city.getName())
        this.selectAllCities = this.userCities.length == this.cities.length ? true : false
        this.newUserCities = this.getUserCities(this)

        this.userProfile = this.user.userProfile
        this.phone = this.user.phone
        this.emailAlarm = this.user.emailAlarm
        this.smsAlarm = this.user.smsAlarm

        this.sorterHelper = new SorterHelper(this.newUserCities)
        this.newUserCities = this.sorterHelper.sortCities()
    }

    mounted(): void {
        window.addEventListener('resize', this.handleCitySorting)
    }

    checkAllCitiesSelected(): boolean {
        let allChecked = true
        this.newUserCities.forEach((city) => {
            if (!city.checked) {
                allChecked = false
            }
        })
        return allChecked
    }

    private handleCitySorting(): void {
        let element = document.getElementById('cities')
        if (element) {
            this.newUserCities = this.sorterHelper.handleCitySorting(element)
        }
    }

    getUserCities(self: any): any[] {
        return self.cities.map(function (city: City) {
            return { text: city.getName(), checked: self.userCities.includes(city.getName()) }
        }, self)
    }

    get canSubmit(): boolean {
        return this.checkedCities.length > 0
    }

    get checkedCities(): any[] {
        return this.newUserCities?.filter(function (value) {
            return value.checked
        })
    }

    onSelectAllCities(): void {
        if (this.selectAllCities) {
            this.newUserCities.forEach((city) => (city.checked = true))
        } else {
            this.newUserCities.forEach((city) => (city.checked = false))
        }
    }


    @Emit('update-user-requested')
    onSubmit(): User {
        let newUserCities = this.checkedCities
        newUserCities = newUserCities?.map(function (city) {
            return city['text']
        })

        return new User({
            isAdmin: this.isAdmin,
            floodAccess: this.floodAccess,
            permissions: this.permissions,
            cities: this.cities.filter((c) => newUserCities.includes(c.getName())),
            email: this.email,
            language: this.userLanguage,
            phone: this.phone,
            emailAlarm: this.emailAlarm,
            smsAlarm: this.smsAlarm,
            userProfile: this.userProfile
        })
    }

    @Watch('newUserCities', { deep: true, immediate: true })
    onCitiesCheckboxesChanged(): void {
        this.selectAllCities = this.checkAllCitiesSelected() ? true : false
    }
}
