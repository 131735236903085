import { InvalidDTOError } from '@/api/errors/InvalidDTOError'
import { UserDTO } from './UserDTO'
import { UserRaw } from './UserRaw'

export class UserDTOFactory {
    static fromRaw(userRaw: UserRaw): UserDTO {
        const mandatoryFields: any[] = [
            userRaw.username,
            userRaw.flood,
            userRaw.admin,
            userRaw.cities,
            userRaw.cities_alarm,
            userRaw.email,
            userRaw.email_alarms,
            userRaw.sms_alarms,
            userRaw.phone,
            userRaw.language,
            userRaw.userprofile_id
        ]

        if (mandatoryFields.indexOf(null) !== -1) {
            throw new InvalidDTOError(UserDTOFactory.name, userRaw)
        }
        return new UserDTO(
            userRaw.username,
            userRaw.rights,
            userRaw.flood,
            userRaw.admin,
            userRaw.cities,
            userRaw.cities_alarm,
            userRaw.email,
            userRaw.email_alarms,
            userRaw.phone,
            userRaw.sms_alarms,
            userRaw.language,
            userRaw.userprofile_id
        )
    }
}
