














import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator'

import { City } from '@/model/City'
import {
    allRepresentations,
    FloodDepth,
    FloodRisk,
    IRepresentation,
    Rainfall,
    Representation,
    ReturnPeriod
} from '@/model/Representation'
import { Permissions } from '@/model/Permissions'
import { IAccessControlService } from '@/services/acl/IAccessControlService'

@Component({
    components: {}
})
export default class RepresentationSelector extends Vue {
    @Prop() private city?: City
    @Prop() private showStationRepresentation?: boolean

    @VModel() selectedRepresentation!: string

    representations: IRepresentation[] = []
    private accessControlService!: IAccessControlService

    created(): void {
        this.representations = allRepresentations
        this.selectedRepresentation = FloodRisk.id
        this.accessControlService = this.$services.get<IAccessControlService>('acl')

    }

    @Watch('city')
    onCityChanged(): void {
        if (this.city?.getCatchments().isEmpty) {
            let representation = this.getRepresentation(this.selectedRepresentation)
            if (representation && representation.requiresCatchments) {
                this.selectedRepresentation = this.representations[0].id
            }
        }
    }

    getRepresentation(id: string): IRepresentation | undefined {
        return this.representations.find((r) => r.id === id)
    }

    hasAccess(representation: Representation): boolean {
        if (representation === FloodRisk || representation === Rainfall) {
            return true
        } else if (representation === ReturnPeriod) {
            return this.accessControlService.hasPermission(Permissions.FULL_ACCESS)
        } else if (representation === FloodDepth) {
            return this.accessControlService.hasFloodAccess
        } else {
            return false
        }
    }
}
