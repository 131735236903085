













import { ITranslationService } from '@/services/translation/ITranslationService'
import { ISettingService } from '@/services/setting/ISettingService'
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'

@Component
export default class LanguageField extends Vue {
    @Prop({ required: true }) value!: string

    private availableLanguages = new Map<string, string>()
    private selectedLanguage = ""
    private defaultLangauge = ""

    private translationService!: ITranslationService
    private settingService!: ISettingService

    created(): void {
        this.selectedLanguage = this.value
        this.translationService = this.$services.get<ITranslationService>('translation')
        this.settingService = this.$services.get<ISettingService>('setting')
        this.displayAvailableLanguages()
    }

    // Emit the updated value with `input` event
    @Emit('input')
    onLanguageChange(newLanguage: string): string {
        return newLanguage
    }

    async displayAvailableLanguages(): Promise<void> {
         this.availableLanguages = await this.translationService.getAvailableLanguages()
         if(this.selectedLanguage === ""){
            this.selectedLanguage = (await (this.settingService.getSettings())).getDefaultLanguage()
         }
    }

    @Watch('selectedLanguage')
    onSelectedLanguageChange(newVal:string): void {
        this.onLanguageChange(newVal)
    }
}
