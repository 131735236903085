





























































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'

import { City } from '@/model/City'
import { User } from '@/model/User'
import { Permissions, PermissionsValues } from '@/model/Permissions'

import RequiredField from './RequiredField.vue'
import RequiredPasswordField from './RequiredPasswordField.vue'
import LanguageField from './LanguageField.vue'
import { SorterHelper } from '@/model/SorterHelper'

@Component({
    components: {
        RequiredField,
        RequiredPasswordField,
        LanguageField
    }
})
export default class UserCreateFormComponent extends Vue {
    @Prop({ type: Array }) readonly cities!: City[]

    private sorterHelper!: SorterHelper

    private allPermissions: Permissions[] = PermissionsValues
    private selectAllCities = false
    private userCities: any[] = []

    email = ''
    username = ''
    password = ''
    confirmedPassword = ''
    isAdmin = false
    floodAccess = false
    permissions: Permissions = Permissions.LIVE_ONLY
    userProfile = ''
    phone = ''
    emailAlarm = false
    smsAlarm = false
    language = ""

    confirmedPasswordFieldHasError: boolean | null = null
    confirmedPasswordFieldType = 'is-required'
    confirmedPasswordFieldMessage = ''


    created(): void {
        this.userCities = this.getUserCities(this)
        this.sorterHelper = new SorterHelper(this.userCities)
        this.userCities = this.sorterHelper.sortCities()
    }

    mounted(): void {
        window.addEventListener('resize', this.handleCitySorting)
    }

    validate(): void {
        if (this.confirmedPassword.trim().length === 0) {
            this.confirmedPasswordFieldHasError = true
            this.confirmedPasswordFieldMessage = `${this.$t('dialogs.create_user.errors.password_required')}`
        } else if (this.password !== this.confirmedPassword) {
            this.confirmedPasswordFieldHasError = true
            this.confirmedPasswordFieldMessage = `${this.$t('dialogs.create_user.errors.password_identical')}`
        } else {
            this.confirmedPasswordFieldHasError = false
        }
    }

    checkAllCitiesSelected(): boolean {
        let allChecked = true
        this.userCities.forEach((city) => {
            if (!city.checked) {
                allChecked = false
            }
        })
        return allChecked
    }

    private handleCitySorting(): void {
        let element = document.getElementById('cities')
        if (element) {
            this.userCities = this.sorterHelper.handleCitySorting(element)
        }
    }

    getUserCities(self: any): any[] {
        return self.cities.map(function (city: City) {
            return { text: city.getName(), checked: false }
        }, self)
    }

    get canSubmit(): boolean {
        return (
            this.username.trim() !== '' &&
            this.password.trim() !== '' &&
            this.password === this.confirmedPassword &&
            this.checkedCities.length > 0
        )
    }

    get checkedCities(): any[] {
        return this.userCities?.filter(function (value) {
            return value.checked
        })
    }

    onSelectAllCities(): void {
        if (this.selectAllCities) {
            this.userCities.forEach((city) => (city.checked = true))
        } else {
            this.userCities.forEach((city) => (city.checked = false))
        }
    }

    @Emit('create-user-requested')
    onSubmit(): User {
        let userCities = this.checkedCities
        userCities = userCities?.map(function (city) {
            return city['text']
        })
        return new User({ ...this, cities: this.cities.filter((c) => userCities.includes(c.getName())) })
    }

    @Watch('confirmedPasswordFieldHasError')
    onValidationChanged(): void {
        if (this.confirmedPasswordFieldHasError) {
            this.confirmedPasswordFieldType = 'is-danger'
        } else {
            this.confirmedPasswordFieldType = 'is-success'
            this.confirmedPasswordFieldMessage = ''
        }
    }

    @Watch('userCities', { deep: true, immediate: true })
    onCitiesCheckboxesChanged(): void {
        this.selectAllCities = this.checkAllCitiesSelected() ? true : false
    }
}
