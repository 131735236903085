import { City } from '@/model/City'
import { Permissions } from '@/model/Permissions'

export interface IUser {
    readonly username: string
    readonly password: string
    readonly isAdmin: boolean
    readonly floodAccess: boolean
    readonly permissions: Permissions
    readonly cities: City[]

    email: string
    phone: string
    userProfile: string
    emailAlarm: boolean
    smsAlarm: boolean
    citiesAlarms: City[]
    language: string
}

export class User implements IUser {
    readonly username: string
    readonly password: string
    readonly isAdmin: boolean
    readonly floodAccess: boolean
    readonly permissions: Permissions
    readonly cities: City[]
    email: string
    phone: string
    userProfile: string
    emailAlarm: boolean
    smsAlarm: boolean
    citiesAlarms: City[]
    language: string

    static UNKNOWN = new User({ username: '?', isAdmin: false, floodAccess: false, permissions: Permissions.LIVE_ONLY })

    constructor(init: Partial<IUser>) {
        this.username = init.username || ''
        this.password = init.password || ''
        this.isAdmin = init.isAdmin || false
        this.floodAccess = init.floodAccess || false
        this.permissions = init.permissions || Permissions.LIVE_ONLY
        this.cities = init.cities || []
        this.email = init.email || ''
        this.phone = init.phone || ''
        this.userProfile = init.userProfile || ''
        this.emailAlarm = init.emailAlarm || false
        this.smsAlarm = init.smsAlarm || false
        this.citiesAlarms = init.citiesAlarms || []
        this.language = init.language || ''
    }
    
}