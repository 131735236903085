import axios, { AxiosError } from 'axios'

import { makeURL } from '../config'
import { ApiError } from '../errors/ApiError'
import { ServerError } from '../errors/ServerError'

import { UserCollectionDTO } from './dto/UserCollectionDTO'
import { UserCollectionDTOFactory } from './dto/UserCollectionDTOFactory'
import { UserCollectionRaw } from './dto/UserCollectionRaw'
import { UserCreateDTO } from './dto/UserCreateDTO'
import { UserDTO } from './dto/UserDTO'
import { UserDTOFactory } from './dto/UserDTOFactory'
import { UserRaw } from './dto/UserRaw'
import { UserUpdateDTO } from './dto/UserUpdateDTO'

export class UserAPI {
    private routes = {
        getUsers: makeURL('/api/users/all'),
        getUser: makeURL('/api/users/${username}'),
        me: makeURL('/api/users/me'),
        createUser: makeURL('/api/users'),
        updateUser: makeURL('/api/users/${username}/update_from_admin'),
        deleteUser: makeURL('/api/users/${username}'),
        updateUserPassword: makeURL('/api/users/me/password'),
        updateUserSettings: makeURL('/api/users/me/update')
    }

    async getUsers(page: number, itemsPerPage: number, ascendingOrder: boolean): Promise<UserCollectionDTO> {
        try {
            const params = {
                page: page,
                take: itemsPerPage,
                sorting: ascendingOrder ? 'asc' : 'desc'
            }

            const result = await axios.get(this.routes.getUsers, {
                params: params
            })

            return UserCollectionDTOFactory.fromRaw(result.data as unknown as UserCollectionRaw)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    async getUser(username: string): Promise<UserDTO> {
        try {
            let url = this.routes.getUser
            url = url.replace('${username}', username)
            const rawUser = await axios.get(url)
            return UserDTOFactory.fromRaw(rawUser.data.user as unknown as UserRaw)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    async getMe(): Promise<UserDTO | null> {
        let rawUser = null
        try {
            rawUser = await axios.get(this.routes.me)
        } catch (exception) {
            this.handleError(this.routes.me, exception as AxiosError<ServerError>)
        }

        if (!rawUser?.data?.user) {
            return null
        }

        return UserDTOFactory.fromRaw(rawUser.data.user as unknown as UserRaw)
    }

    async createUser(userCreate: UserCreateDTO): Promise<void> {
        try {
            await axios.post(this.routes.createUser, userCreate)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    async updateUser(username: string, userUpdate: UserUpdateDTO): Promise<void> {
        try {
            let url = this.routes.updateUser
            url = url.replace('${username}', username)
            await axios.patch(url, userUpdate)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    async deleteUser(username: string): Promise<void> {
        try {
            let url = this.routes.deleteUser
            url = url.replace('${username}', username)
            await axios.delete(url)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    async updateUserLanguage(language: string): Promise<void> {
        try {
            const body = {
                language: language
            }

            await axios.patch(this.routes.updateUserSettings, body)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    async updateUserPassword(currentPassword: string, newPassword: string, confirmNewPassword: string): Promise<void> {
        try {
            const body = {
                current: currentPassword,
                new: newPassword,
                confirmation: confirmNewPassword
            }
            await axios.patch(this.routes.updateUserPassword, body)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    async updateUserSettings(
        cities_alarm: Array<string>,
        email: string,
        email_alarms: boolean,
        phone: string,
        sms_alarms: boolean
    ): Promise<void> {
        try {
            const body = {
                cities_alarm: cities_alarm,
                email: email,
                email_alarms: email_alarms,
                phone: phone,
                sms_alarms: sms_alarms
            }
            await axios.patch(this.routes.updateUserSettings, body)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    handleError(url: string, error: AxiosError<ServerError>): void {
        if (error.request.status === 500) {
            console.error(url)
            console.error(error)
        }
    }
}
