export class UserUpdateDTO {
    constructor(
        readonly email: string,
        readonly rights: string,
        readonly flood: boolean,
        readonly admin: boolean,
        readonly cities: string[],
        readonly language: string,
        readonly phone: string,
        readonly userprofile_id: string,
        readonly email_alarms: boolean,
        readonly sms_alarms: boolean
    ) {}
}
