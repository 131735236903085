export class Station {
    constructor(
        readonly id: number,
        readonly no: number,
        readonly name: string,
        readonly location: [number, number],
        readonly stationTypes: string[],
        readonly waterLevels?: [number, number]
    ) {}

    getLngLat(): [number, number] {
        return [this.location[1], this.location[0]]
    }
}
