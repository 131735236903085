import { UserDTO } from '@/api/user/dto/UserDTO'
import { City } from '@/model/City'
import { CityCollection } from '@/model/CityCollection'
import { Permissions } from '@/model/Permissions'
import { User } from '@/model/User'

export class UserFactory {
    static fromUserDTO(dto: UserDTO, cityCollection: CityCollection): User {
        return new User({
            username: dto.username,
            isAdmin: dto.admin,
            floodAccess: dto.flood,
            permissions: UserFactory.fromString(dto.rights),
            cities: dto.cities
                .map((cityName) => cityCollection.getByName(cityName))
                .filter((city) => city != null) as City[],
            email: dto.email,
            phone: dto.phone,
            emailAlarm: dto.emailAlarm,
            smsAlarm: dto.smsAlarm,
            citiesAlarms: dto.citiesAlarm
                .map((cityName) => cityCollection.getByName(cityName))
                .filter((city) => city != null) as City[],
            language: dto.language,
            userProfile: dto.userProfile
        })
    }

    private static fromString(value: string): Permissions {
        const toUpper = value.toUpperCase()
        switch (toUpper) {
            case 'FULL_ACCESS':
                return Permissions.FULL_ACCESS
            case 'HISTORY':
                return Permissions.HISTORY
            default:
                return Permissions.LIVE_ONLY
        }
    }
}
